<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary mb-2 mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
            <button
              type="button"
              class="btn btn-dark mb-2 mr-2"
              data-toggle="modal"
              data-target="#addModal"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table
                id="tech_logs"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Error</th>
                    <th>Status</th>
                    <th>Origin</th>
                    <th>Resolved by</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="log in techLogs" :key="log.id">
                    <td>{{ formatDate(log.created_at) }}</td>
                    <td>{{ log.error }}</td>
                    <td>
                      <span
                        v-if="log.status == 'resolved'"
                        class="btn btn-success"
                        >Resolved</span
                      >
                      <span
                        v-if="log.status == 'unresolved'"
                        class="btn btn-danger"
                        >Unresolved</span
                      >
                    </td>
                    <td>{{ log.origin.toUpperCase() }}</td>
                    <td>
                      <span v-if="log.user != null">{{
                        log.user.firstname
                      }}</span>
                    </td>
                    <td class="d-flex">
                      <button
                        v-if="log.status == 'resolved'"
                        @click.prevent="showFix(log.fix)"
                        class="btn btn-sm m-1 btn-success"
                      >
                        <i class="fa fa-wrench"></i>
                      </button>
                      <button
                        @click.prevent="showEdit(log)"
                        class="btn btn-sm m-1 btn-warning"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        @click.prevent="showDelete(log.id)"
                        class="btn btn-sm m-1 btn-danger"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Modal -->
    <div
      class="modal fade"
      id="addModal"
      role="dialog"
      aria-labelledby="addModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>New Tech Log</p>
                </div>
                <form id="addModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="addModalError">Error </label>
                        <textarea
                          class="form-control"
                          id="addModalError"
                          rows="3"
                          v-model="newTechLog.error"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalFix">Fix/Solution</label>
                        <textarea
                          class="form-control"
                          id="editModalFix"
                          rows="3"
                          v-model="newTechLog.fix"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <p>Origin</p>
                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="GeroCare App"
                            v-model="newTechLog.origin"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">GeroCare App</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-info"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="CRM"
                            v-model="newTechLog.origin"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">CRM</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <p>Status</p>
                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="resolved"
                            v-model="newTechLog.status"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Resolved</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            v-model="newTechLog.status"
                            value="unresolved"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Unresolved</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>
            <button
              id="btn-add"
              @click.prevent="saveNewTechLog()"
              class="btn btn-success"
              :disabled="loadingNew"
            >
              <b-spinner small v-if="loadingNew"></b-spinner>
              <span v-else>Save <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Modal -->
    <div
      class="modal fade"
      id="editModal"
      role="dialog"
      aria-labelledby="editModalTitle"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p>Edit Tech Log</p>
                </div>
                <form id="editModalTitle">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalError">Error </label>
                        <textarea
                          class="form-control"
                          id="editModalError"
                          rows="3"
                          v-model="editTechLog.error"
                          readonly
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <label for="editModalFix">Fix/Solution</label>
                        <textarea
                          class="form-control"
                          id="editModalFix"
                          rows="3"
                          v-model="editTechLog.fix"
                        ></textarea>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <p>Origin</p>
                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="GeroCare App"
                            disabled
                            v-model="editTechLog.origin"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">GeroCare App</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-info"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="CRM"
                            disabled
                            v-model="editTechLog.origin"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">CRM</span>
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 mt-4 mt-md-0">
                      <p>Status</p>
                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="resolved"
                            v-model="editTechLog.status"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Resolved</span>
                        </label>
                      </div>

                      <div class="n-chk">
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            v-model="editTechLog.status"
                            value="unresolved"
                          />
                          <span class="new-control-indicator"></span
                          ><span class="new-radio-content">Unresolved</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark" data-dismiss="modal">
              <i class="flaticon-delete-1"></i> Discard
            </button>

            <button
              id="btn-add"
              @click.prevent="saveEditTechLog()"
              class="btn btn-success"
              :disabled="loadingEdit"
            >
              <b-spinner small v-if="loadingEdit"></b-spinner>
              <span v-else>Update <i class="fa fa-paper-plane"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="searchModal"
      role="dialog"
      aria-labelledby="searchModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Date Search</label>
                  <select class="form-control" v-model="search" id="dateSearch">
                    <option value="all">All</option>
                    <option value="thisweek">This Week (From Monday)</option>
                    <option value="pastweek">Past Week (Last 7 days)</option>
                    <option value="lastmonth">Last Month</option>
                    <option value="thismonth">This Month</option>
                    <option value="thisyear">This Year</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="form-group" v-if="search == 'custom'">
                  <label>Select a range</label>
                  <div class="input-group">
                    <flat-pickr
                      v-model="date"
                      :config="config"
                      class="form-control"
                      placeholder="Select date"
                      @on-close="handlePicker()"
                      name="date"
                    >
                    </flat-pickr>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleSearch()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete  -->
    <div
      class="modal fade"
      id="deleteModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-danger">
                    Are you sure you want to delete this Tech Log?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Cancel</button>

            <button
              @click.prevent="completeDelete()"
              class="btn btn-danger text-white"
              :disabled="loadingDelete"
            >
              <b-spinner small v-if="loadingDelete"></b-spinner>
              <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Fix  -->
    <div
      class="modal fade"
      id="fixModal"
      role="dialog"
      aria-labelledby="editContactModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="searchModalLabel">
              Fix for this error <i class="fa fa-wrench"></i>
            </h5>
          </div>
          <div class="modal-body">
            <i class="fa fa-close" data-dismiss="modal"></i>
            <div class="add-contact-box">
              <div class="add-contact-content">
                <div class="text-center">
                  <p class="text-dark" v-if="viewFix != null">
                    {{ viewFix }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Dismiss</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { newTechLog, editTechLog } from "@/models/techlog.js";
import { DatePickerConfig } from "@/models/datepicker.js";
import $ from "jquery";
var moment = require("moment");

export default {
  name: "TechLogs",

  data() {
    return {
      loading: false,
      techLogs: [],

      search: "thismonth",
      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",

      sponsors: {},
      selectedSponsor: {},

      today: moment().toDate(),

      loadingNew: false,
      newTechLog: new newTechLog(),

      loadingEdit: false,
      editTechLog: new editTechLog(),

      loadingDelete: false,
      deleteTechLogId: null,

      viewFix: null,
    };
  },

  mounted() {
    this.fetchTechLogs();
  },

  methods: {
    initTable() {
      var dt = $("#tech_logs").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4]).visible(false);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchTechLogs();
        $("#searchModal").modal("hide");
      }
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    fetchTechLogs() {
      this.loading = true;
      http
        .post(endpoints.FETCH_TECH_LOGS, {
          search: this.search,
          customStart: this.customStart,
          customEnd: this.customEnd,
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.techLogs = response;
          setTimeout(() => {
            this.initTable();
          }, 500);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveNewTechLog() {
      if (
        this.newTechLog.error == "" ||
        this.newTechLog.status == "" ||
        this.newTechLog.origin == ""
      ) {
        this.$toast.error("Missing required input fields");
      } else {
        this.loadingNew = true;
        http
          .post(endpoints.NEW_TECH_LOG, { ...this.newTechLog })
          .then((response) => {
            this.loadingNew = false;
            this.$toast.success(response);
            $("#addModal").modal("hide");
            this.newTechLog = new newTechLog();
            this.fetchTechLogs();
          })
          .catch((error) => {
            this.loadingNew = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showEdit(x) {
      this.editTechLog = x;
      $("#editModal").modal("show");
    },

    saveEditTechLog() {
      if (
        this.editTechLog.error == "" ||
        this.editTechLog.status == "" ||
        this.editTechLog.origin == ""
      ) {
        this.$toast.error("Missing input fields");
      } else {
        this.loadingEdit = true;
        http
          .put(endpoints.UPDATE_TECH_LOG, { ...this.editTechLog })
          .then((response) => {
            this.loadingEdit = false;
            this.$toast.success(response);
            $("#editModal").modal("hide");
            this.editTechLog = new editTechLog();
            // this.fetchTechLogs()
          })
          .catch((error) => {
            this.loadingEdit = false;
            this.$toast.error(
              error.response?.data?.message || error.response?.message
            );
          });
      }
    },

    showDelete(id) {
      this.deleteTechLogId = id;
      $("#deleteModal").modal("show");
    },

    showFix(fix) {
      this.viewFix = fix;
      $("#fixModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(endpoints.DELETE_TECH_LOG.replace(":id", this.deleteTechLogId))
        .then((response) => {
          console.log(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.$toast.success(response);
          this.techLogs = this.techLogs.filter(
            (x) => x.id != this.deleteTechLogId
          );
        })
        .catch((error) => {
          console.log(error);
          this.loadingDelete = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
};
</script>

<style scoped src="@/assets/css/tables/table-basic.css"></style>
