export class newTechLog {
  constructor() {
    this.error = "";
    this.status = "";
    this.origin = "";
    this.fix = "";
  }
}

export class editTechLog {
  constructor() {
    this.id = "";
    this.error = "";
    this.status = "";
    this.origin = "";
    this.fix = "";
  }
}
